/* storybook-check-ignore */
import React from 'react';

interface Props {
  fillColor?: string;
}

const LinkedinLogo: React.FC<Props> = ({ fillColor = 'white' }) => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="linkedinTitle"
  >
    <title id="linkedinTitle">Follow Opendoor on LinkedIn.</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0019 11.264V17.8552H14.1439V11.7055C14.1439 10.1606 13.5859 9.10626 12.1894 9.10626C11.1234 9.10626 10.489 9.81679 10.2099 10.5041C10.1081 10.7498 10.0819 11.0918 10.0819 11.4358V17.8552H6.22266C6.22266 17.8552 6.27463 7.43952 6.22266 6.36038H10.0816V7.98973C10.0737 8.00191 10.0636 8.01505 10.0562 8.02687H10.0816V7.98973C10.5943 7.20761 11.5099 6.09033 13.5593 6.09033C16.0984 6.09029 18.0019 7.73323 18.0019 11.264V11.264ZM2.18381 0.82019C0.863556 0.82019 0 1.67791 0 2.80584C0 3.9092 0.838572 4.79295 2.13259 4.79295H2.15837C3.50419 4.79295 4.34117 3.90936 4.34117 2.80584C4.31581 1.67791 3.50419 0.82019 2.18381 0.82019V0.82019ZM0.230469 17.8569H4.08828V6.36206H0.230469V17.8569V17.8569Z"
      fill={fillColor}
    />
  </svg>
);

export default LinkedinLogo;
