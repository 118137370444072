/* storybook-check-ignore */
import styled from '@emotion/styled';

interface IProps {
  flex?: boolean;
}

const flexStyle = (props: IProps) => {
  if (props.flex) {
    return `
    display: flex;

    @media (max-width: 767px) {
      display: block;
      max-width: 100%;
    }
    `;
  }
  return '';
};

export default styled.div<IProps>`
  margin-right: auto;
  margin-left: auto;
  max-width: 1345px;
  position: relative;

  @media (max-width: 1199px) {
    max-width: 833px;
  }

  @media (max-width: 767px) {
    max-width: 327px;
  }

  ${flexStyle};
`;
