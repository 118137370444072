/* storybook-check-ignore */
import { useState } from 'react';

import { Box, Button, Text } from '@opendoor/bricks/core';
import { novo } from '@opendoor/bricks/theme';
import { centsToCurrencyString } from '@opendoor/bricks/helpers/currencyHelpers';

import * as api from '../api';
import { COSMOS_URL, CONSUMER_FE_URL } from '../globals';
import { ExperienceType, useReturnExperience } from '../../helpers/returnBanner';

type CTAButtonProps = {
  experience: ExperienceType;
  loading?: boolean;
  onClick?: () => void;
  url?: string;
  actionText: string;
};

const CTAButton = (props: CTAButtonProps): JSX.Element => {
  const onClick = () => {
    props.onClick && props.onClick();
    if (props.url) {
      window.location.href = props.url;
    }
  };

  return (
    <Button
      maxWidth={['fit-content']}
      style={{
        fontWeight: novo.fontWeights.medium,
      }}
      analyticsName={`cosmos-return-banner-${ExperienceType[props.experience]}`}
      trackImpression
      aria-label="return-banner-cta"
      loading={props.loading}
      onClick={onClick}
    >
      {props.actionText}
    </Button>
  );
};

const getActiveOfferExpiryText = (daysToExpire = 10) => {
  let expiryText = 'is waiting';
  if (daysToExpire < 1) {
    expiryText = 'expires today';
  } else if (daysToExpire < 2) {
    expiryText = 'expires in 1 day';
  } else if (daysToExpire < 3) {
    expiryText = 'expires in 2 days';
  } else if (daysToExpire === 3) {
    expiryText = 'expires in 3 days';
  }
  return expiryText;
};

type HeadlineProps = {
  experience: ExperienceType;
  currentOfferPriceCents?: number;
  previousOfferPriceCents?: number;
  daysToExpire?: number;
};

const Headline = (props: HeadlineProps): JSX.Element | null => {
  switch (props.experience) {
    case ExperienceType.multiple_properties:
      return <>Get your offer today</>;
    case ExperienceType.offer_expired:
      return <>Get your updated offer today</>;
    case ExperienceType.incomplete_flow:
      return <>Finish your offer today</>;
    case ExperienceType.offer_increased:
      return !isNaN(props.currentOfferPriceCents ?? NaN) &&
        !isNaN(props.previousOfferPriceCents ?? NaN) ? (
        <>
          Your offer increased by{' '}
          <Text
            as="span"
            color="brand50"
            style={{
              fontWeight: novo.fontWeights.medium,
            }}
          >
            {centsToCurrencyString(
              (props.currentOfferPriceCents ?? 0) - (props.previousOfferPriceCents ?? 0),
            )}
          </Text>
        </>
      ) : null;
    case ExperienceType.offer_expiring:
    case ExperienceType.offer_decreased:
    case ExperienceType.active_offer:
      return !isNaN(props.currentOfferPriceCents ?? NaN) ? (
        <>
          Your offer of{' '}
          <Text
            as="span"
            color="brand50"
            style={{
              fontWeight: novo.fontWeights.medium,
            }}
          >
            {centsToCurrencyString(props.currentOfferPriceCents ?? 0)}
          </Text>{' '}
          {getActiveOfferExpiryText(props.daysToExpire)}
        </>
      ) : null;
    default:
      return null;
  }
};

const getAddressDisplay = (experience: ExperienceType, address?: string): string => {
  if (!address) {
    return '';
  }
  let text = address;
  if (experience === ExperienceType.multiple_properties) {
    text += ' & others';
  }
  return text;
};

type ExperienceCTAButtonProps = {
  experience: ExperienceType;
  sellerInputUUID?: string;
  customerUUID?: string;
  addressToken?: string;
};

const ExperienceCTAButton = (props: ExperienceCTAButtonProps): JSX.Element | null => {
  let offerDashUrl = COSMOS_URL;
  if (props.addressToken) {
    // Return to sell tab in hub for returning customers
    offerDashUrl = `${CONSUMER_FE_URL}/dashboard/sell/${props.addressToken}`;
  } else if (props.sellerInputUUID) {
    offerDashUrl = `${CONSUMER_FE_URL}/dashboard/${props.sellerInputUUID}/overview`;
  }
  const [loading, setLoading] = useState(false);

  const fnHandleRefresh = (url: string, sellerInputUUID: string) => {
    return async () => {
      setLoading(true);
      await api.refreshOffer(sellerInputUUID);
      window.location.href = url;
    };
  };

  switch (props.experience) {
    case ExperienceType.multiple_properties:
      // eslint-disable-next-line no-case-declarations
      const propertiesUrl = `${COSMOS_URL}/properties`;
      return (
        <CTAButton actionText="View properties" url={propertiesUrl} experience={props.experience} />
      );
    case ExperienceType.offer_expired:
      return props.sellerInputUUID ? (
        <CTAButton
          loading={loading}
          actionText="Refresh your offer"
          onClick={fnHandleRefresh(offerDashUrl, props.sellerInputUUID)}
          experience={props.experience}
        />
      ) : (
        <CTAButton
          actionText="Refresh your offer"
          url={offerDashUrl}
          experience={props.experience}
        />
      );
    case ExperienceType.incomplete_flow:
      // eslint-disable-next-line no-case-declarations
      const sellerFlowUrl = `${CONSUMER_FE_URL}/seller-lead/${props.sellerInputUUID}`;
      return (
        <CTAButton
          actionText="Resume your offer"
          url={sellerFlowUrl}
          experience={props.experience}
        />
      );
    case ExperienceType.offer_increased:
    case ExperienceType.offer_expiring:
    case ExperienceType.offer_decreased:
    case ExperienceType.active_offer:
      return (
        <CTAButton actionText="View your offer" url={offerDashUrl} experience={props.experience} />
      );
    default:
      return null;
  }
};

const ReturnExperienceBanner = () => {
  const {
    returnExperienceData: {
      address,
      addressToken,
      customerUUID,
      currentOfferHeadlinePriceCents,
      previousOfferHeadlinePriceCents,
      offerExpiresInDays,
      sellerInputUUID,
      experienceType,
    },
  } = useReturnExperience();

  if (experienceType === null) {
    return null;
  }

  return (
    <Box
      display={['none', 'none', 'flex']}
      position="absolute"
      top="80"
      left="50%"
      transform="translateX(-50%)"
      zIndex={5}
      pl="32px"
      pr="15px"
      py="15px"
      width="646px"
      background="rgba(242, 241, 240, 0.64)"
      backdropFilter="blur(32px)"
      style={{
        borderRadius: novo.radii.roundedSmooth,
      }}
      justifyContent={[null, null, 'space-between']}
      alignItems="center"
    >
      <Box>
        <Text
          size="130"
          style={{
            fontWeight: novo.fontWeights.medium,
          }}
        >
          <Headline
            experience={experienceType}
            currentOfferPriceCents={currentOfferHeadlinePriceCents}
            previousOfferPriceCents={previousOfferHeadlinePriceCents}
            daysToExpire={offerExpiresInDays}
          />
        </Text>
        <Text mt={'4px'} size="100">
          {getAddressDisplay(experienceType, address)}
        </Text>
      </Box>
      <ExperienceCTAButton
        experience={experienceType}
        sellerInputUUID={sellerInputUUID}
        customerUUID={customerUUID}
        addressToken={addressToken}
      />
    </Box>
  );
};

export default ReturnExperienceBanner;
