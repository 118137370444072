/* storybook-check-ignore */
import React, { useState } from 'react';
import { DEFAULT_NAV_MENU_BUTTON_ID, DEFAULT_NAV_MENU_ID } from '../../shared/MenuPanel';
import { COSMOS_URL } from '../../globals';
import { useObservability } from '../../../helpers/observability';
import Box from '@opendoor/bricks/core/Box';
import Flex from '@opendoor/bricks/core/Flex';
import { Button, Link, List, ListItem } from '@opendoor/bricks/core';
import { useAuth } from '@opendoor/auth-fe';
import { EiChevronDownIcon } from '../../shared/EiChevronDownIcon';
import AccountMenu from '../../account/AccountMenu';

import Grid, { gridStyles } from '../Grid';

import { GlobalNavSVG } from './GlobalNavSVG';
import { OpendoorLogoSVG } from './OpendoorLogoSVG';

export type HeaderNavLinkName = 'sell' | 'buy' | 'buy-and-sell';
interface HeaderProps {
  activeNav?: HeaderNavLinkName;
  extraNavLinks?: NavLinks;
  hideHeaderNavLinks?: boolean;
  isMenuOpen: boolean;
  navMenuButtonId?: string;
  navMenuId?: string;
  toggleMenuOpen: () => void;
}
const redirectToLogin = () => {
  document.location.href = `${COSMOS_URL}/login`;
};

const Header: React.FC<HeaderProps> = (props) => {
  const {
    activeNav = 'sell' as HeaderNavLinkName,
    extraNavLinks,
    hideHeaderNavLinks,
    isMenuOpen = false,
    navMenuButtonId = DEFAULT_NAV_MENU_BUTTON_ID,
    navMenuId = DEFAULT_NAV_MENU_ID,
    toggleMenuOpen = () => {},
  } = props;

  const { trackEvent } = useObservability();
  const [isExpanded, setExpanded] = useState(false);
  const { user: human, authentication } = useAuth();

  const isLoggedIn = authentication.state === 'authenticated';
  const handleMenuClick = () => {
    // TODO: remove categoryOverride
    // observability is initialized with 'cosmos' as the category for all events
    trackEvent('cta-click', 'menu', undefined, { categoryOverride: 'homepage' });
    toggleMenuOpen();
  };
  const handleAccountClick = () => {
    // TODO: remove categoryOverride
    // observability is initialized with 'cosmos' as the category for all events
    trackEvent('cta-click', 'account-icon', undefined, { categoryOverride: 'homepage' });
    authentication.state === 'authenticated' ? setExpanded(!isExpanded) : redirectToLogin();
  };
  const fullName = human?.fullName;
  const firstName = fullName ? fullName.split(' ')[0] : '';
  const baseNavLinks = [
    { name: 'Sell', href: '/', isActive: activeNav === 'sell' },
    { name: 'Buy', href: '/homes', isActive: activeNav === 'buy' },
    { name: 'Buy & Sell', href: '/buy-and-sell', isActive: activeNav === 'buy-and-sell' },
  ];
  const navLinks = [...baseNavLinks, ...(extraNavLinks || [])];

  return (
    <Grid maxWidth="unset" horizontalMargin={false}>
      <Box
        gridColumn={gridStyles.colSpan12}
        background="neutrals0"
        top={0}
        left={0}
        zIndex={8}
        width="100%"
      >
        <SkipLink />
        <Flex
          px={gridStyles.margin}
          justifyContent="space-between"
          height={['56px', null, '80px']}
          alignItems="center"
        >
          <Flex flex={1} alignItems="center">
            <Button
              border={0}
              height="24px"
              minHeight="24px"
              size="sm"
              width="27px"
              analyticsName="cosmos-landing-page-header-menu-button"
              float="left"
              p={0}
              style={{
                background: 'none',
              }}
              aria-controls={navMenuId}
              aria-expanded={isMenuOpen ? 'true' : 'false'}
              aria-haspopup="true"
              aria-label="Navigation menu"
              id={navMenuButtonId}
              onClick={handleMenuClick}
            >
              <GlobalNavSVG />
            </Button>
            <OpendoorLogoSVG />
          </Flex>
          <Box display={['none', null, 'block']} mt="-8px">
            {!hideHeaderNavLinks && (
              <Box
                pl={0}
                _before={{
                  display: 'table',
                  content: "' '",
                }}
                _after={{
                  display: 'table',
                  content: "' '",
                  clear: 'both',
                }}
              >
                <List
                  display="inline-block"
                  variant="accordion"
                  analyticsName="cosmos-landing-page-menu-list"
                >
                  {navLinks.map((nav) => {
                    return (
                      <ListItem display="inline-block" mx={[null, null, 5]} key={nav.name}>
                        <NavLink active={nav.isActive} href={nav.href}>
                          {nav.name}
                        </NavLink>
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            )}
          </Box>
          <Flex
            flex={[null, null, 1]}
            justifyContent="flex-end"
            textAlign="right"
            onClick={handleAccountClick}
          >
            {isLoggedIn ? (
              <Box cursor="pointer" fontWeight="semibold">
                Welcome {firstName} &nbsp;
                <Box display="inline-block" width="1em" height="1em" mb="0.2em">
                  <EiChevronDownIcon />
                </Box>
                <AccountMenu isExpanded={isExpanded} />
              </Box>
            ) : (
              <Link
                href={'#'}
                fontWeight="medium"
                analyticsName="cosmos-sign-in"
                aria-label=""
                color="neutrals100"
                _hover={{
                  color: 'neutrals100',
                }}
              >
                Sign In
              </Link>
            )}
          </Flex>
        </Flex>
      </Box>
    </Grid>
  );
};

export default Header;

// since the header is implemented as a fixed position element, we need to
// add a placeholder element to the page to prevent the content from jumping
// when the return experience banner is added into the DOM
export const HeaderPlaceholder = () => <Box height={['45px', '45px', '110px']} />;

export type NavLinks = Array<{
  name: string;
  href: string;
  isActive?: boolean;
}>;

const NavLink: React.FC<{ href: string; elementName?: string; active?: boolean }> = (props) => (
  <Link
    color="neutrals100"
    opacity={props.active ? 1 : 0.4}
    fontWeight="medium"
    _hover={{
      color: '#00000',
      opacity: 1,
    }}
    _active={{
      color: '#00000',
      opacity: 1,
    }}
    _visited={{
      color: '#00000',
      opacity: 1,
    }}
    {...props}
    analyticsName="cosmos-landing-page-nav-link"
    aria-label="View link"
    href={props.href}
    aria-current={props.active}
  >
    {props.children}
  </Link>
);

const SkipLink: React.FC = (_props) => {
  return (
    <Button
      border={0}
      bottom={0}
      color="brand50"
      justifyContent="center"
      left={0}
      outline={0}
      position="absolute"
      top="0"
      transform="translateX(-100%)"
      width="100%"
      zIndex={1}
      display="flex"
      backgroundColor="neutrals0"
      aria-label="Skip navigation menu and view main content"
      _focus={{
        transform: 'translateX(0)',
      }}
      analyticsName="cosmos-landing-page-skip-to-main-content"
      onClick={() => {
        // there should only be 1 main on any page and it should wrap the main content
        const mainElem = document.querySelector('main');
        mainElem && mainElem.focus();
      }}
    >
      Skip to main content
    </Button>
  );
};
