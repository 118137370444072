/* storybook-check-ignore */
import React from 'react';

const YoutubeLogo: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="youtubeTitle"
  >
    <title id="youtubeTitle">Follow Opendoor on YouTube.</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.794 5.41471C20.6541 5.64577 21.3344 6.32614 21.5655 7.18621C21.9891 8.75232 22.002 12.0001 22.002 12.0001C22.002 12.0001 22.002 15.2607 21.5783 16.8139C21.3473 17.674 20.6669 18.3544 19.8068 18.5854C18.2536 19.0091 12.002 19.0091 12.002 19.0091C12.002 19.0091 5.75035 19.0091 4.19708 18.5854C3.337 18.3544 2.65664 17.674 2.42557 16.8139C2.00195 15.2478 2.00195 12.0001 2.00195 12.0001C2.00195 12.0001 2.00195 8.75232 2.41274 7.19905C2.6438 6.33897 3.32416 5.65861 4.18424 5.42755C5.73751 5.00392 11.9891 4.99109 11.9891 4.99109C11.9891 4.99109 18.2407 4.99109 19.794 5.41471ZM15.1855 12.0001L9.99938 15.0039V8.99623L15.1855 12.0001Z"
      fill="white"
    />
  </svg>
);

export default YoutubeLogo;
