/* storybook-check-ignore */
import React from 'react';

interface Props {
  fillColor?: string;
}

const TwitterLogo: React.FC<Props> = ({ fillColor = 'white' }) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="twitterTitle"
    >
      <title id="twitterTitle">Follow Opendoor on Twitter.</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 2.83581C19.2639 3.17786 18.474 3.40982 17.6442 3.51339C18.4915 2.98129 19.1402 2.1373 19.4476 1.13471C18.6527 1.62747 17.7754 1.98528 16.8405 2.17925C16.092 1.3418 15.0271 0.82019 13.8461 0.82019C11.5803 0.82019 9.74312 2.74671 9.74312 5.12144C9.74312 5.45824 9.77936 5.78719 9.84936 6.10175C6.44001 5.92218 3.41684 4.2093 1.39347 1.6065C1.03977 2.24083 0.838563 2.97995 0.838563 3.76893C0.838563 5.26167 1.56343 6.57878 2.66324 7.34936C1.99085 7.32579 1.35847 7.13182 0.804838 6.80942V6.86315C0.804838 8.94693 2.21958 10.686 4.09546 11.0818C3.75177 11.1788 3.38935 11.2326 3.01442 11.2326C2.74948 11.2326 2.49327 11.205 2.24206 11.1526C2.76445 12.8629 4.27919 14.1066 6.07387 14.1407C4.67037 15.294 2.90069 15.9794 0.978564 15.9794C0.647387 15.9794 0.321177 15.9585 0 15.9204C1.81592 17.1432 3.97177 17.8562 6.28883 17.8562C13.8362 17.8562 17.9617 11.3007 17.9617 5.61554L17.9479 5.05856C18.754 4.45566 19.4513 3.69817 20 2.83581V2.83581Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default TwitterLogo;
