/* storybook-check-ignore */

import imgixUrl from 'helpers/imgixUrl';
import * as React from 'react';

/**
 * This was copied over from OD's shared components
 * We edited the implementation to allow for:
 *    different imgix images for each established breakpoint
 *    eager and lazy load for LCP benefits
 *      NOTE: eager load should only be used on the LCP image. Using 'eager' on other images for loading can negatively impact our LCP CWVs
 */

interface RvImgixImgProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  /** @param {[string, ...(string | null)[]] | string} responsiveSrc - This element can be used when we have different image filenames for different device sizes. Similar to Opendoor's components, this element will take an array or string value for the responsiveSrc prop. The 0th element will be used for breakpoints 0-479, etc (see our readme). The image filename will be transformed into a proper imgix url to utilize the image optimization features */
  responsiveSrc: [string, ...(string | null)[]] | string;
}

// Fetchpriority exists on the <img> tag but is not yet declared in the Typescript types for <img>
// Per this link: https://react-typescript-cheatsheet.netlify.app/docs/advanced/types_react_api/#adding-non-standard-attributes
// We can add the attribute. I've verified it works in the browser as expected
declare module 'react' {
  interface ImgHTMLAttributes<T> extends HTMLAttributes<T> {
    fetchpriority?: 'auto' | 'high' | 'low';
  }
}

// Breakpoint widths defined to match breakpoints OD uses in their element attribute arrays
const breakpoints = [0, 480, 768, 992, 1200, 1600];

// Takes an array and appends 'null' until the array length matches the breakpoints array length
const matchArrWidth = (inputArr: [string, ...(string | null)[]]) => {
  while (inputArr.length < breakpoints.length) {
    inputArr.push(null);
  }
  return inputArr;
};

// Takes in an array of varying lengths and return an array of filesnames that correlates
// to the breakpoints array
const getResponsiveSrcSet = (responsiveSrcs: [string, ...(string | null)[]]): string[] => {
  let rightLengthArr: [string, ...(string | null)[]];
  if (typeof responsiveSrcs == 'string') {
    rightLengthArr = matchArrWidth([responsiveSrcs]);
  } else {
    rightLengthArr = matchArrWidth(responsiveSrcs);
  }

  const fileNameArr: [string, ...(string | null)[]] = [rightLengthArr[0]];
  rightLengthArr.forEach((s, i) => {
    if (i == 0) {
      return;
    }
    const nonNull = s || fileNameArr[i - 1];
    fileNameArr.push(nonNull);
  });

  return breakpoints.map((w, i) => imgixUrl(`${fileNameArr[i]}?w=${w} ${w}w`));
};

/**
 * This is our verison of Opendoor's ImgixImg component
 * @see RvImgixImgProps for prop details
 */
const RvImgixImg: React.FC<RvImgixImgProps> = ({ responsiveSrc, loading = 'lazy', ...props }) => {
  let nonNullSrcSet: string[];
  // Get an array of src filenames that matches the length of the breakpoints array
  if (typeof responsiveSrc == 'string') {
    nonNullSrcSet = getResponsiveSrcSet([responsiveSrc]);
  } else {
    nonNullSrcSet = getResponsiveSrcSet(responsiveSrc);
  }

  // Turn that array of filenames into an array of imgix urls that correspond to each breakpoint
  // NOTE: need to reverse the array because we are using min-widths and the biggest breakpoint source needs to
  // be the 0th element
  const sourceArr = nonNullSrcSet
    .map((src, i) => {
      return <source media={`(min-width: ${breakpoints[i]}px)`} srcSet={src} key={`${src}-${i}`} />;
    })
    .reverse();

  const fetchPriority = loading == 'lazy' ? 'low' : 'high';

  return (
    <picture style={{ width: '100%', height: '100%' }}>
      {sourceArr}
      <img
        alt=""
        {...props}
        src={nonNullSrcSet[0]}
        loading={loading}
        fetchpriority={fetchPriority}
      />
    </picture>
  );
};

export default RvImgixImg;
