/* storybook-check-ignore */

import { useCallback, useEffect, useState } from 'react';
import { Box } from '@opendoor/bricks/core';
import { setAppElement } from '@opendoor/bricks/complex';
import { novo } from '@opendoor/bricks/theme';
import { ThemedLite } from '@opendoor/bricks/theme/ODThemeLite';
import SelectiveSpritesheet from '@opendoor/bricks/core/Icon/SelectiveSpritesheet';
import ArrowLeft from '@opendoor/bricks/core/Icon/SpritesheetIcons/ArrowLeft';
import ArrowRight from '@opendoor/bricks/core/Icon/SpritesheetIcons/ArrowRight';
import CloseIcon from '@opendoor/bricks/core/Icon/SpritesheetIcons/Close';
import { sizes as textSizes } from '@opendoor/bricks/theme/novo/components/text';

import MenuPanel from './menu/MenuPanel';
import Header, { HeaderPlaceholder } from './header/Header';
import Footer from './Footer';
import MenuOverlay from './menu/MenuOverlay';
import GlobalStyles from 'components/shared/GlobalStyles';
import ReturnExperienceBanner from './ReturnExperienceBanner';
import Head from 'next/head';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  useEffect(() => {
    setAppElement('#__next');
  }, []);

  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  const toggleMenuOpen = () => setMenuOpen(!isMenuOpen);

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape' && isMenuOpen) {
      setMenuOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  return (
    <ThemedLite
      theme={{
        ...novo,
        components: {
          ...novo.components,
          Text: {
            ...novo.components.Text,
            sizes: {
              ...novo.components.Text.sizes,
              // NOTE: This is a hack to fix responsive sizing due to issues in the feature's figma designs
              // TODO(@product-growth): Remove this when the design system is updated
              ...UpdatedTextSizing,
            },
          } as typeof novo.components.Text,
          Heading: {
            ...novo.components.Heading,
            sizes: {
              ...novo.components.Heading.sizes,
              // NOTE: This is a hack to fix responsive sizing due to issues in the feature's figma designs
              // TODO(@product-growth): Remove this when the design system is updated
              ...UpdatedTextSizing,
            },
          } as typeof novo.components.Heading,
        },
      }}
    >
      <Head>
        <link
          rel="preload"
          crossOrigin="anonymous"
          href="https://cdn-cosmos.opendoor.com/fonts/graphik-regular/Graphik-Regular.woff"
          as="font"
        />
        <link
          rel="preload"
          crossOrigin="anonymous"
          href="https://cdn-cosmos.opendoor.com/fonts/graphik-medium/Graphik-Medium.woff"
          as="font"
        />
        <link
          rel="preload"
          crossOrigin="anonymous"
          href="https://cdn-cosmos.opendoor.com/fonts/graphik-semibold/Graphik-Semibold.woff"
          as="font"
        />
        <link
          rel="preload"
          crossOrigin="anonymous"
          href="https://cdn-cosmos.opendoor.com/fonts/graphik-bold/Graphik-Bold.woff"
          as="font"
        />
      </Head>
      <GlobalStyles />
      <SelectiveSpritesheet icons={[ArrowLeft, ArrowRight, CloseIcon]} />
      <ReturnExperienceBanner />
      <MenuPanel active={isMenuOpen} toggleMenuOpen={toggleMenuOpen} />
      <MenuOverlay active={isMenuOpen} />
      <Header isMenuOpen={isMenuOpen} toggleMenuOpen={toggleMenuOpen} />
      <main tabIndex={-1}>
        <HeaderPlaceholder />
        <Box
          width="100%"
          margin="0"
          // TODO: save scroll position from before menu is open
          position={isMenuOpen ? 'fixed' : 'unset'}
        >
          {children}
          <Footer />
        </Box>
      </main>
    </ThemedLite>
  );
};

const UpdatedTextSizing: typeof textSizes = {
  80: {
    fontSize: '11px',
    lineHeight: '150',
    letterSpacing: '-1.4',
  },
  90: {
    fontSize: '12px',
    lineHeight: '150',
    letterSpacing: '-1.4',
  },
  100: {
    fontSize: ['14px', null, null, '18px'],
    lineHeight: '140',
    letterSpacing: '-1.4',
  },
  110: {
    fontSize: ['16px', null, '14px', '16px'],
    lineHeight: ['25.6px', null, '25.6px', '160%'],
    letterSpacing: ['-1.4%', '-0.014em', '-0.014em', '-0.018em'],
  },
  '110-L': {
    fontSize: '16px',
    lineHeight: '160',
    letterSpacing: '-1.4',
  },
  120: {
    fontSize: '18px',
    lineHeight: '120',
    letterSpacing: '-2.4',
  },
  // using this for StickyCTAUtility
  130: {
    fontSize: ['16px', null, '19px'],
    lineHeight: '19px',
    letterSpacing: '-2.4',
  },
  140: {
    fontSize: '22px',
    lineHeight: '112',
    letterSpacing: '-2.4',
  },
  150: {
    fontSize: ['16px', null, null, '24px'],
    lineHeight: ['130%', null, null, '112%'],
    letterSpacing: ['-0.014em', null, null, '-0.028em'],
  },
  160: {
    fontSize: ['18px', '19px', null, '28px'],
    lineHeight: ['120%', null, null, '110%'],
    letterSpacing: ['-0.024em', '-0.02em', '-0.028em', '-0.028em'],
  },
  // using this size for the title in Carousel
  170: {
    fontSize: ['32px', '36px', '44px', '56px'],
    lineHeight: ['35.2px', '36px', '44px', '56px'],
    letterSpacing: ['-2.8', '-3', '-3.4'],
    fontWeight: 500,
  },
  //using this for the browse-homes section
  180: {
    fontSize: ['16px', '18px', null, '28px'],
    lineHeight: ['120%', null, null, '110%'],
    letterSpacing: ['-0.024em', '-0.02em', '-0.028em', '-0.028em'],
  },
  190: {
    fontSize: '40px',
    lineHeight: '100',
    letterSpacing: '-3',
  },
  200: {
    fontSize: '44px',
    lineHeight: '100',
    letterSpacing: '-3',
  },
  210: {
    fontSize: ['20px', null, '48px'],
    lineHeight: '100',
    letterSpacing: '-3.4',
  },
  220: {
    fontSize: ['22.2px', '28px', '36px', '56px'],
    lineHeight: ['106%', '110%', '100%'],
    letterSpacing: ['-0.024em', '-0.028em', '-0.03em', '-0.034em;'],
  },
  230: {
    fontSize: ['32px', '36px', '44px', '64px'],
    lineHeight: ['110%', '100%'],
    letterSpacing: ['-0.028em', '-0.03em', '-0.03em', '-0.034em;'],
  },
  240: {
    fontSize: ['36px', null, '80px'],
    lineHeight: '100',
    letterSpacing: '-3.4',
  },
  250: {
    fontSize: ['44px', null, '88px'],
    lineHeight: '100',
    letterSpacing: '-3.4',
  },
  260: {
    fontSize: ['60px', null, '96px'],
    lineHeight: '100',
    letterSpacing: '-3.4',
  },
  270: {
    fontSize: ['68px', null, '96px'],
    lineHeight: '100',
    letterSpacing: '-3.4',
  },
};

export default Layout;
