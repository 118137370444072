import React from 'react';
import { Box, BoxProps, Heading, HeadingProps } from '@opendoor/bricks/core';
import AddressSearch from './AddressSearch';
import { TrackingTaxonomy } from '../globals';
import { CTAProps } from '@opendoor/bricks/complex/CtaInput';

export const SmallContainer: React.FC<BoxProps> = (props) => (
  <Box
    mx="auto"
    maxWidth="720px"
    position="relative"
    _after={{ content: '""', display: 'block', clear: 'both' }}
    sx={{
      '@media (max-width: 767px)': {
        maxWidth: '90%',
      },
    }}
    {...props}
  />
);

export const Disclaimer: React.FC<Omit<HeadingProps, 'size' | 'as'>> = (props) => (
  <Heading
    size="UNSAFE_h5"
    as="h5"
    color="neutrals70"
    textAlign="center"
    mt="15px"
    fontSize="s0"
    sx={{ display: ['block', null, null, null, 'none'] }}
    {...props}
  />
);

export const Bullet = () => (
  <Box as="span" px="5px">
    {String.fromCharCode(0x2022)}
  </Box>
);

interface StickyCTABarProps {
  visible: boolean;
  trackingTaxonomy: TrackingTaxonomy;
  placeholderText?: string;
  actionText?: CTAProps['actionText'];
}

const StickyCTABar = ({
  visible,
  trackingTaxonomy,
  actionText,
  placeholderText,
}: StickyCTABarProps) => (
  <Box
    className="sticky-cta-bar"
    position="fixed"
    zIndex={9}
    top={0}
    left="50%"
    width="600px"
    padding="5px"
    opacity={visible ? 1 : 0}
    visibility={visible ? 'visible' : 'hidden'}
    transform="translateX(-50%)"
    transition=" all ease-in-out 0.3s"
    borderTop="1px solid"
    borderColor="neutrals20"
    boxShadow="0px 4px 20px rgba(68, 89, 109, 0.1)"
    sx={{
      top: '43px',
      position: 'fixed',
      zIndex: 9,
      width: '100%',
      margin: 0,
      marginTop: 2,
      padding: '8px',
      background: 'neutrals0',
      '& label': {
        display: 'none',
      },
    }}
  >
    {visible ? (
      <SmallContainer>
        <AddressSearch
          trackingTaxonomy={trackingTaxonomy}
          inputLocation="sticky-cta"
          actionText={actionText ?? 'Get my free offer'}
          placeholderText={placeholderText}
          size="sm"
          displayResultsAbove={false}
        />
      </SmallContainer>
    ) : null}
  </Box>
);

export default StickyCTABar;
