/* storybook-check-ignore */
import React from 'react';

const OpendoorLogoWhite: React.FC = () => (
  <svg
    fill="none"
    width="111px"
    height="23px"
    viewBox="0 0 112 23"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#fff" clipPath="url(#clip0_490_8297)">
      <path
        d="m11.761 10.004c0-5.3607-0.9681-7.5828-3.6298-7.5828-2.3475 0-3.5333 1.5578-3.5333 6.9644 0 5.3375 0.91959 7.537 3.63 7.537 2.3957 0 3.5331-1.5351 3.5331-6.9186zm-11.761-0.13738c0-6.0252 4.3799-8.4076 8.2763-8.4076 4.1621 0 8.0822 2.3597 8.0822 8.041 0 6.0708-4.3799 8.3847-8.2762 8.3847-4.1619 0-8.0823-2.5199-8.0823-8.0181z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
      <path
        d="m23.924 16.968c-0.8228 0-1.4761-0.3437-1.9119-0.8248v-8.3389c0.3873-0.13735 0.9923-0.41239 1.9603-0.41239 1.8392 0 2.8798 1.1454 2.8798 4.4215 0 4.1925-1.0165 5.1546-2.9282 5.1546zm2.1537-11.569c-1.6941 0-3.0489 0.75592-4.0656 1.5807h-0.1209l0.0467-1.3708c0.0043-0.12485-0.1095-0.22433-0.2405-0.21025l-3.358 0.35939c-0.0997 0.01074-0.1749 0.0905-0.1749 0.18541v16.588c0 0.1111 0.1022 0.1977 0.219 0.1852l3.454-0.3694c0.0995-0.0106 0.1746-0.0903 0.1746-0.1852v-3.1095c0-0.8017-0.024-1.3974-0.024-1.3974 0.5324 0.1374 1.3311 0.2289 1.8392 0.2289 4.0412 0 6.9934-2.0846 6.9934-6.8497 0-4.1924-2.444-5.6356-4.743-5.6356z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
      <path
        d="m36.067 10.256 4.138-0.2062c0-2.7719-0.3869-3.78-1.839-3.78-1.2343 0-2.2023 1.0996-2.299 3.9862zm-3.8234 1.3516c0-3.9862 3.1215-6.2083 6.3158-6.2083 3.3006 0 5.3719 1.5437 5.4898 5.5825 0.0024 0.0785-0.0651 0.1447-0.1484 0.1447h-7.6818c-0.0834 0-0.1507 0.0662-0.1486 0.145 0.1082 3.6389 1.6018 4.6431 4.062 4.6431 1.2008 0 2.169-0.2642 2.9792-0.6043 0.0597-0.0252 0.1289-0.0104 0.1715 0.0368l0.3218 0.3583c0.0485 0.054 0.0479 0.134-0.0034 0.1854-0.8827 0.8813-2.6799 1.994-5.0421 1.994-4.2107 0-6.3158-2.3826-6.3158-6.2772z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
      <path
        d="m53.998 5.399c-1.7664 0-3.3636 0.75609-4.6461 1.6265h-0.0969l0.0476-1.4444c0.0038-0.11076-0.0971-0.19862-0.2131-0.1863l-3.3863 0.36344c-0.0995 0.01056-0.1749 0.09015-0.1749 0.18506v11.485c0 0.1003 0.086 0.1815 0.1918 0.1815h3.4641c0.1058 0 0.1917-0.0812 0.1917-0.1815v-9.6006c0.5324-0.11463 1.4036-0.29776 2.2508-0.29776 1.1372 0 1.6697 0.43528 1.6697 1.8327v8.0656c0 0.1003 0.086 0.1815 0.1918 0.1815h3.4882c0.1059 0 0.192-0.0812 0.192-0.1815v-8.9589c0-2.0619-1.1132-3.07-3.1704-3.07z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
      <path
        d="m67.55 15.571c-0.3873 0.1375-1.0407 0.5041-2.0328 0.5041-1.8392 0-2.8071-1.1685-2.8071-4.7651 0-3.7802 1.2099-4.9943 2.9522-4.9943 0.8956 0 1.4763 0.48105 1.8877 0.96211v8.2932zm3.8476-12.417c0-1.1842 0.0426-2.2722 0.0651-2.7187 0.0065-0.13065-0.1107-0.2356-0.2481-0.22398l-3.4853 0.3747c-0.1016 0.008628-0.1793 0.089097-0.1793 0.18577v3.207c0 0.82459 0.0484 1.6722 0.0484 1.6722-0.5082-0.13752-1.1858-0.25197-1.839-0.25197-3.7753 0-7.0182 1.9015-7.0182 6.6667 0 4.1692 2.3473 5.8188 4.7432 5.8188 1.6939 0 3.0491-0.6415 4.0898-1.489h0.0967l-0.0491 1.2645c-0.0056 0.1347 0.1187 0.2412 0.2595 0.2224l3.3201-0.351c0.1125-0.0148 0.1962-0.106 0.1962-0.2136v-14.164z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
      <path
        d="m82.075 11.791c0-4.6276-0.9436-5.4982-2.5893-5.4982-1.5246 0-2.4199 1.0538-2.4199 4.9942 0 4.6965 0.7985 5.7042 2.565 5.7042 1.4523 0 2.4442-0.8017 2.4442-5.2002zm-9.0746-0.1145c0-4.3987 3.2909-6.2771 6.558-6.2771 3.5089 0 6.5822 1.9931 6.5822 6.1625 0 4.3985-3.2427 6.3231-6.5822 6.3231-3.5089 0-6.558-2.0163-6.558-6.2085z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
      <path
        d="m96.509 11.791c0-4.6276-0.9435-5.4982-2.5893-5.4982-1.5246 0-2.4198 1.0538-2.4198 4.9942 0 4.6965 0.7987 5.7042 2.5651 5.7042 1.4521 0 2.444-0.8017 2.444-5.2002zm-9.0747-0.1145c0-4.3987 3.2911-6.2771 6.558-6.2771 3.5089 0 6.5819 1.9931 6.5819 6.1625 0 4.3985-3.2423 6.3231-6.5819 6.3231-3.5087 0-6.558-2.0163-6.558-6.2085z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
      <path
        d="m109.57 5.399c-1.403 0-2.783 1.0081-3.532 1.764h-0.097l0.047-1.5395c4e-3 -0.131-0.116-0.23524-0.253-0.22063l-3.346 0.35551c-0.1 0.01057-0.175 0.09016-0.175 0.18524v11.45c0 0.119 0.102 0.2155 0.228 0.2155h3.392c0.125 0 0.228-0.0965 0.228-0.2155v-9.2916c0.338-0.29793 0.895-0.59586 1.33-0.59586 0.218 0 0.364 0.04595 0.509 0.29793 0.459 0.71014 0.823 1.1206 1.863 1.1206 0.944 0 1.788-0.70821 1.788-1.8078 0-1.1227-0.82-1.7182-1.982-1.7182z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </g>
  </svg>
);

export default OpendoorLogoWhite;
