import * as React from 'react';
import { GetServerSideProps, InferGetServerSidePropsType } from 'next';

import Head from 'next/head';
import { AddContextualData } from '@opendoor/observability/slim';

import GlobalStyles from 'components/shared/GlobalStyles';
import StickyCTAStyles from 'components/shared/StickyCTAStyles';
import StickyCTABar from 'components/shared/StickyCTABar';
import MenuPanel, { MenuOverlay } from 'components/shared/MenuPanel';

import Header, { HeaderPlaceholder } from 'components/shared/Header';

import * as WordpressHeadGlobal from 'components/WordpressPorts';
import { useObservability } from '../../helpers/observability';
import { HOMEPAGE_SCHEMA } from 'schema';
import ReturnExperienceBanner from 'components/shared/ReturnExperienceBanner';
import UnsupportedBrowserBanner, {
  isUnsupportedBrowser,
} from 'components/shared/UnsupportedBrowserBanner';
import { useAuth } from '@opendoor/auth-fe';
import { useOnLoad } from 'components/shared/useOnLoad';
import { fetchLandingPageBySlug, LandingPageProps } from 'cms';
import LazyLoad from 'react-lazyload';
import Footer from 'components/shared/Footer';
import RichTextRenderer from 'cms/renderer';
import landingPageEntryLookup from 'cms/entries/landingPage';
import { useReturnExperience } from 'helpers/returnBanner';
import { getPreloadsFromLoaderData } from 'helpers/landingPages';
import AppBanner from 'components/shared/AppBanner';
import dynamic from 'next/dynamic';
import * as api from '../../components/api';
import { StickyCTABarContextProvider, useStickyCTABarContext } from 'helpers/StickyCTABarContext';
import OutageBanner from 'components/shared/OutageBanner';
import Layout from 'components/landing-pages/Layout';
import { ParallaxProvider } from 'react-scroll-parallax';

const Contact = dynamic(
  () => import(/* webpackChunkName: "BelowFoldSections" */ 'components/shared/Contact'),
);

export const getServerSideProps: GetServerSideProps<LandingPageProps> = async (context) => {
  // collapse the slug from an array to a single string
  let slug = '';
  // if slug is a string and not an array
  if (typeof context.query.slug === 'string') {
    slug = context.query.slug;
  } else if (Array.isArray(context.query.slug)) {
    slug = context.query.slug.join('/');
  }

  try {
    const props = await fetchLandingPageBySlug({ slug, requestUrl: context.resolvedUrl });
    if (!props) {
      return {
        notFound: true,
      };
    }

    // check if the page is set to public, and reject if appropriate
    // this is intended to block non-public pages from being directly accessed by users,
    // while still letting them be used in experiments as treatments
    if (context.query.public && props.public === false) {
      return {
        notFound: true,
      };
    }

    const markets = await api.fetchActiveMarkets((context.req as any).requestCache);
    return {
      props: {
        ...props,
        markets,
      },
    };
  } catch (e) {
    console.error(e);
    return {
      notFound: true,
    };
  }
};

const LandingPage: React.FC<InferGetServerSidePropsType<typeof getServerSideProps>> = (props) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const { visible: stickyVisible } = useStickyCTABarContext();

  const { trackEvent } = useObservability();
  React.useEffect(() => {
    try {
      // TODO: Make trackEvent handle initObservable not loaded better
      // TODO: Make cosmos handle uncaught exceptions better - currently we
      // just keep trying to rerender the same code until we don't get an
      // exception
      trackEvent(
        'viewed',
        'new_homepage',
        {
          'homepage-2019-test': 'homepage-2019-3',
          logged_in: false,
        },
        { categoryOverride: 'homepage' },
      );
    } catch (e) {
      /* eslint-disable-next-line no-console */
      console.error('Unable to homepage view event', e);
    }
  }, [trackEvent]);

  const { returnExperienceData, returnExperienceBannerShown } = useReturnExperience();
  const { authentication, refresh } = useAuth();

  const isAfterLoad = useOnLoad();
  React.useEffect(() => {
    // don't fire off the import until the document is finished loading
    if (authentication.state !== 'not_authenticated' || !isAfterLoad) {
      return;
    }
    import('components/shared/GoogleOneTap').then(({ promptGoogleOneTap }) => {
      promptGoogleOneTap('cosmos-homepage', refresh);
    });
  }, [authentication.state, isAfterLoad, refresh]);
  const menuTrigger = React.useRef<Element>();
  const toggleMenu = () => {
    setIsMenuOpen((isOpen) => {
      // If opening the menu, store which focused element triggered the action (if any)
      if (!isOpen && document.activeElement) {
        menuTrigger.current = document.activeElement;
      }
      // If closing the menu, restore focus to the trigger element (if any)
      if (isOpen && menuTrigger.current) {
        (menuTrigger.current as HTMLElement).focus();
        menuTrigger.current = undefined;
      }
      // Toggle the menu
      return !isOpen;
    });
  };
  const contextualData = { landingPageSlug: props.fields.slug };

  const imagePreloads = getPreloadsFromLoaderData(props.idToLoaderData);
  if (props.fields.slug === 'flapple' || props.fields.slug === 'homepage-flapple') {
    // Let's return the rebranded layout for collabathon
    return (
      <AddContextualData data={contextualData}>
        <Layout>
          <RichTextRenderer.Component
            body={props.fields.body}
            idToLoaderData={props.idToLoaderData}
            entryLookup={landingPageEntryLookup}
          />
        </Layout>
      </AddContextualData>
    );
  }

  return (
    <AddContextualData data={contextualData}>
      <React.Fragment>
        {WordpressHeadGlobal.Head()}
        <Head>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(HOMEPAGE_SCHEMA) }}
          />
          {imagePreloads.map((h, i) => (
            <link key={i} rel="preload" href={h.url} as="image" media={h.media} />
          ))}
        </Head>
        <WordpressHeadGlobal.Page>
          <GlobalStyles />
          <StickyCTAStyles />
          <StickyCTABar visible={stickyVisible} trackingTaxonomy="new_homepage" />
          <MenuPanel active={isMenuOpen} toggleMenuOpen={toggleMenu} />
          <MenuOverlay active={isMenuOpen} />
          <Header isMenuOpen={isMenuOpen} toggleMenuOpen={toggleMenu} />
          <main tabIndex={-1}>
            <HeaderPlaceholder />
            {isUnsupportedBrowser() && <UnsupportedBrowserBanner />}
            {!isUnsupportedBrowser() && <OutageBanner />}
            {!isUnsupportedBrowser() && returnExperienceBannerShown && (
              <ReturnExperienceBanner {...returnExperienceData} />
            )}
            <RichTextRenderer.Component
              body={props.fields.body}
              idToLoaderData={props.idToLoaderData}
              entryLookup={landingPageEntryLookup}
            />
            <Contact markets={props.markets} campaignName="buy-sell-v2" />
            <AppBanner />
          </main>
          <LazyLoad once offset={300}>
            <Footer />
          </LazyLoad>
        </WordpressHeadGlobal.Page>
      </React.Fragment>
    </AddContextualData>
  );
};

// don't care about 'any' here because LandingPage has proper typing
const LandingPageWithContext = (props: any) => {
  return (
    <ParallaxProvider>
      <StickyCTABarContextProvider>
        <LandingPage {...props} />
      </StickyCTABarContextProvider>
    </ParallaxProvider>
  );
};

export default LandingPageWithContext;
