/* storybook-check-ignore */
import { useEffect, useState, useRef } from 'react';
import { Text, Box, Flex, Button, Icon, List } from '@opendoor/bricks/core';
import SelectiveSpritesheet from '@opendoor/bricks/core/Icon/SelectiveSpritesheet';
import ChevronRight from '@opendoor/bricks/core/Icon/SpritesheetIcons/ChevronRight';
import ChevronDown from '@opendoor/bricks/core/Icon/SpritesheetIcons/ChevronDown';
import ArrowLeft from '@opendoor/bricks/core/Icon/SpritesheetIcons/ArrowLeft';
import ArrowRight from '@opendoor/bricks/core/Icon/SpritesheetIcons/ArrowRight';
import Close16 from '@opendoor/bricks/core/Icon/SpritesheetIcons/Close16';
import { IconName } from '@opendoor/bricks/core/Icon';

import { COSMOS_URL } from '../../globals';
import MenuListItem from './MenuListItem';

const NAV_MENU_BACKGROUND_COLOR = '#faf9f8';
export const NAV_MENU_TEXT_COLOR = '#706F6E';

interface MenuPanelProps {
  active: boolean;
  navMenuButtonId: string;
  navMenuId: string;
  toggleMenuOpen: () => void;
}

const MenuPanel = ({ active, toggleMenuOpen, navMenuButtonId, navMenuId }: MenuPanelProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [activeElement, setActiveElement] = useState<string | undefined>(undefined);
  const subMenuContent = menuContent?.find((item) => item.elementName === activeElement)?.items;

  useEffect(() => {
    if (active && containerRef.current) {
      containerRef.current.focus();
    }
  }, [active]);

  useEffect(() => {
    if (!active) {
      setActiveElement(undefined);
    }
  }, [active]);

  const trapFocus = (e: any) => {
    if (e.key !== 'Tab') return;

    const containerElements = containerRef.current?.querySelectorAll(
      'a[href], button, input, select, textarea, [tabindex]:not([tabindex="-1"])',
    );

    const firstElement = containerElements && containerElements[0];
    const lastElement = containerElements && containerElements[containerElements.length - 1];

    // tabbing forwards on the last element (Careers)
    if (!e.shiftKey && document.activeElement === lastElement) {
      (firstElement as HTMLElement).focus();
      return e.preventDefault();
    }

    // tabbing backwards on the first element (Close button)
    if (e.shiftKey && document.activeElement === firstElement) {
      (lastElement as HTMLElement).focus();
      e.preventDefault();
    }
  };

  return (
    <>
      <SelectiveSpritesheet icons={[ArrowLeft, ArrowRight, ChevronDown, ChevronRight, Close16]} />
      <Box
        id={navMenuId}
        aria-labelledby={navMenuButtonId}
        onKeyDown={(e) => e.key === 'Escape' && toggleMenuOpen()}
        ref={containerRef}
        tabIndex={-1}
        role="dialog"
        aria-modal="true"
        minWidth={['100%', null, subMenuContent ? '100%' : '50%', subMenuContent ? '66%' : '33%']}
        px={[6, null, 9, 11]}
        py={[6, null, 8]}
        position="fixed"
        top={0}
        left={0}
        zIndex={12}
        height="100%"
        overflowY="auto"
        visibility={active ? 'visible' : 'hidden'}
        opacity={active ? 1 : 0}
        transform={active ? 'translateX(0)' : 'translateX(-150px)'}
        transition="ease-in-out"
        display={active ? 'inherit' : undefined}
        style={{
          backgroundColor: NAV_MENU_BACKGROUND_COLOR,
        }}
      >
        <Flex flexDirection="row" justifyContent="space-between" alignItems="center" mb={11}>
          <Button
            display={[subMenuContent ? 'block' : 'none', null, 'none']}
            aria-label="Close subnavigation menu"
            analyticsName="cosmos-landing-page-menu-mobile-back-button"
            variant="icon"
            onClick={() => setActiveElement(undefined)}
            onKeyDown={trapFocus}
            size="sm"
            border="none"
            shadow="none"
            style={{ background: NAV_MENU_BACKGROUND_COLOR, width: '24px' }}
          >
            <Icon
              name="arrow-left"
              size={24}
              style={{
                color: NAV_MENU_TEXT_COLOR,
              }}
            />
          </Button>
          <Text
            display={[subMenuContent ? 'none' : 'block', null, 'block']}
            fontWeight="medium"
            size="110"
          >
            Menu
          </Text>
          <Button
            aria-label="Close menu"
            analyticsName="cosmos-landing-page-menu-close-button"
            variant="icon"
            onClick={toggleMenuOpen}
            onKeyDown={trapFocus}
            size="sm"
            border="none"
            shadow="none"
            style={{ background: '#FAF9F8', width: '24px' }}
          >
            <Icon
              name="close"
              size={24}
              style={{
                color: NAV_MENU_TEXT_COLOR,
              }}
            />
          </Button>
        </Flex>
        <Flex>
          <Flex flex={1} display={[subMenuContent ? 'none' : 'flex', null, 'flex']}>
            <List
              variant="accordion"
              analyticsName="cosmos-landing-page-menu-list"
              flexDir="column"
              position="relative"
            >
              {menuContent?.map(({ displayText, href, elementName, ...props }, index) => {
                return (
                  <MenuListItem
                    key={`menu-item-${index}`}
                    displayText={displayText}
                    href={href}
                    elementName={elementName}
                    isActive={activeElement === 'elementName'}
                    setActiveElement={(activeElement: string) => setActiveElement(activeElement)}
                    onKeyDown={index === menuContent.length - 1 ? trapFocus : undefined}
                    {...props}
                  />
                );
              })}
            </List>
          </Flex>
          {subMenuContent && (
            <Flex flex={1}>
              <List
                variant="accordion"
                analyticsName="cosmos-landing-page-sub-menu-list"
                pl={[0, null, '85px']}
                flexDir="column"
              >
                {[...subMenuContent]?.map(({ displayText, href, elementName }, index) => {
                  return (
                    <MenuListItem
                      key={`sub-menu-item-${index}`}
                      displayText={displayText}
                      href={href}
                      elementName={elementName}
                      hideIcon
                      onKeyDown={index === subMenuContent.length - 1 ? trapFocus : undefined}
                    />
                  );
                })}
              </List>
            </Flex>
          )}
        </Flex>
      </Box>
    </>
  );
};

export interface MenuItem {
  displayText: string;
  elementName: string;
  href?: string;
  items?: Array<MenuItem>;
  icon?: IconName;
}

const menuContent: Array<MenuItem> = [
  {
    elementName: 'sell',
    displayText: 'Sell',
    items: [
      {
        elementName: 'request-an-offer',
        displayText: 'Request an offer',
        href: '/',
      },
      {
        elementName: 'pricing',
        displayText: 'Pricing',
        href: '/w/pricing',
      },
      {
        elementName: 'reviews',
        displayText: 'Reviews',
        href: '/w/reviews',
      },
      {
        elementName: 'stories',
        displayText: 'Stories',
        href: '/w/stories',
      },
    ],
  },
  {
    elementName: 'buy',
    displayText: 'Buy',
    items: [
      {
        elementName: 'browse-homes',
        displayText: 'Browse homes',
        href: '/w/homes',
      },
      {
        elementName: 'exclusives',
        displayText: 'Exclusives',
        href: 'https://opendoor.com/exclusive"',
      },
    ],
  },
  {
    elementName: 'buy-and-sell',
    displayText: 'Buy and sell',
    items: [
      {
        elementName: 'buy-and-sell-with-opendoor',
        displayText: 'Buy and sell with Opendoor',
        href: '/buy-and-sell',
      },
      {
        elementName: 'builder-trade-up',
        displayText: 'Builder trade-up',
        href: '/w/builder',
      },
    ],
  },
  {
    elementName: 'download-app',
    displayText: 'Download app',
    href: 'https://opendoor.app.link/homepage',
    icon: 'arrow-right',
  },
  {
    elementName: 'agents',
    displayText: 'Agents',
    items: [
      {
        elementName: 'make-an-offer',
        displayText: 'Make an offer',
        href: '/w/agents#alp-buy--form',
      },
      {
        elementName: 'request-an-offer',
        displayText: 'Request an offer',
        href: '/w/agents',
      },
    ],
  },
  {
    elementName: 'partners',
    displayText: 'Partners',
    items: [
      {
        elementName: 'vendors',
        displayText: 'Vendors',
        href: '/w/vendor-partnerships',
      },
      {
        elementName: 'builder',
        displayText: 'Builder',
        href: '/w/partner/builder',
      },
    ],
  },
  {
    elementName: 'about',
    displayText: 'About',
    items: [
      {
        elementName: 'company',
        displayText: 'Company and mission',
        href: '/w/about',
      },
      {
        elementName: 'faq',
        displayText: 'FAQ',
        href: '/faq',
      },
      {
        elementName: 'press',
        displayText: 'Press',
        href: `${COSMOS_URL}/articles/topic/press`,
      },
      {
        elementName: 'contact',
        displayText: 'Contact',
        href: '/w/contact-us',
      },
    ],
  },
  {
    elementName: 'resources',
    displayText: 'Resources',
    items: [
      {
        elementName: 'blog',
        displayText: 'Blog',
        href: `${COSMOS_URL}/articles/topic/blog`,
      },
      {
        elementName: 'education',
        displayText: 'Education',
        href: `${COSMOS_URL}/articles/topic/education`,
      },
      {
        elementName: 'home-sale-calculator',
        displayText: 'Home sale calculator',
        href: '/w/home-sale-calculator',
      },
      {
        elementName: 'mortgage-calculator',
        displayText: 'Mortgage calculator',
        href: '/w/mortgage-calculator',
      },
      {
        elementName: 'briefs',
        displayText: 'Briefs',
        href: `${COSMOS_URL}/articles/topic/briefs`,
      },
    ],
  },
  {
    elementName: 'faq',
    displayText: 'FAQ',
    href: '/faq',
  },
  {
    elementName: 'help-center',
    displayText: 'Help center',
    href: 'https://help.opendoor.com',
  },
  {
    elementName: 'careers',
    displayText: 'Careers',
    href: '/jobs',
  },
];

export const DEFAULT_NAV_MENU_BUTTON_ID = 'nav-menu-button';
export const DEFAULT_NAV_MENU_ID = 'nav-menu';

MenuPanel.defaultProps = {
  navMenuButtonId: DEFAULT_NAV_MENU_BUTTON_ID,
  navMenuId: DEFAULT_NAV_MENU_ID,
};

export default MenuPanel;
