/* storybook-check-ignore */
import React from 'react';

interface Props {
  fillColor?: string;
}

const FacebookLogo: React.FC<Props> = ({ fillColor = 'white' }) => (
  <svg
    width="9"
    height="18"
    viewBox="0 0 9 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="facebookTitle"
  >
    <title id="facebookTitle">Follow Opendoor on Facebook.</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.66109 0.823735L6.5024 0.82019C4.07718 0.82019 2.5099 2.46578 2.5099 5.01276V6.94582H0.339433C0.151879 6.94582 0 7.10143 0 7.29337V10.0941C0 10.2861 0.152052 10.4415 0.339433 10.4415H2.5099V17.5088C2.5099 17.7007 2.66178 17.8562 2.84933 17.8562H5.68118C5.86873 17.8562 6.02061 17.7005 6.02061 17.5088V10.4415H8.55839C8.74594 10.4415 8.89782 10.2861 8.89782 10.0941L8.89886 7.29337C8.89886 7.20121 8.86301 7.11295 8.79946 7.04773C8.7359 6.98251 8.64931 6.94582 8.55926 6.94582H6.02061V5.30714C6.02061 4.51953 6.20401 4.1197 7.20655 4.1197L8.66074 4.11917C8.84812 4.11917 9 3.96356 9 3.77179V1.17111C9 0.979521 8.84829 0.82409 8.66109 0.823735V0.823735Z"
      fill={fillColor}
    />
  </svg>
);

export default FacebookLogo;
