import { Box } from '@opendoor/bricks/core';
/*
  Saves us from making a network request to https://imgdrop.imgix.net/2023-06-1687823014581-88813.svg

  storybook-check-ignore
*/
const _opendoorLogoSVG = ({ height = '20' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="135"
      height={height}
      viewBox="0 0 93 21"
      fill="none"
    >
      <g clipPath="url(#clip0_836_12236)">
        <path
          d="M9.73808 8.83411C9.73808 4.08388 8.93645 2.11476 6.73257 2.11476C4.7888 2.11476 3.80698 3.49518 3.80698 8.28613C3.80698 13.0159 4.56841 14.9649 6.81265 14.9649C8.7963 14.9649 9.73808 13.6046 9.73808 8.83411ZM0 8.71241C0 3.37332 3.62664 1.26221 6.85285 1.26221C10.2991 1.26221 13.5451 3.35319 13.5451 8.38755C13.5451 13.7671 9.91842 15.8175 6.69221 15.8175C3.24608 15.8175 0 13.5845 0 8.71241Z"
          fill="black"
        />
        <path
          d="M19.8099 15.0054C19.1286 15.0054 18.5877 14.7008 18.2269 14.2746V6.88521C18.5475 6.7635 19.0485 6.51978 19.85 6.51978C21.3728 6.51978 22.2345 7.53477 22.2345 10.4377C22.2345 14.1529 21.3929 15.0054 19.8099 15.0054ZM21.5932 4.75381C20.1905 4.75381 19.0687 5.42366 18.2269 6.15451H18.1268L18.1654 4.9398C18.169 4.82918 18.0747 4.74102 17.9663 4.7535L15.1858 5.07196C15.1032 5.08148 15.041 5.15216 15.041 5.23626V18.7051V19.9355C15.041 20.034 15.1256 20.1108 15.2223 20.0997L18.0823 19.7723C18.1647 19.763 18.2269 19.6923 18.2269 19.6082V18.7051V16.8528C18.2269 16.1424 18.207 15.6146 18.207 15.6146C18.6478 15.7363 19.3091 15.8174 19.7299 15.8174C23.076 15.8174 25.5205 13.9701 25.5205 9.74761C25.5205 6.03265 23.4968 4.75381 21.5932 4.75381Z"
          fill="black"
        />
        <path
          d="M29.8651 9.05745L33.2913 8.87474C33.2913 6.41849 32.971 5.52521 31.7686 5.52521C30.7466 5.52521 29.9451 6.49962 29.8651 9.05745ZM26.6992 10.2552C26.6992 6.7229 29.2838 4.75378 31.9288 4.75378C34.6617 4.75378 36.3768 6.12171 36.4744 9.70061C36.4764 9.77021 36.4205 9.82887 36.3515 9.82887H29.9909C29.9219 9.82887 29.8661 9.88754 29.8678 9.95729C29.9575 13.1818 31.1942 14.0717 33.2313 14.0717C34.2256 14.0717 35.0272 13.8376 35.698 13.5362C35.7475 13.5139 35.8048 13.527 35.84 13.5688L36.1065 13.8863C36.1467 13.9342 36.1462 14.0051 36.1037 14.0506C35.3728 14.8316 33.8847 15.8175 31.9288 15.8175C28.4423 15.8175 26.6992 13.7063 26.6992 10.2552Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.7103 4.75379C43.2476 4.75379 41.9252 5.42379 40.8633 6.19506H40.783L40.8224 4.91513C40.8255 4.81699 40.742 4.73913 40.6459 4.75005L37.842 5.0721C37.7596 5.08146 37.6973 5.15199 37.6973 5.23609V15.4129C37.6973 15.5019 37.7684 15.5738 37.856 15.5738H40.7243C40.812 15.5738 40.8831 15.5019 40.8831 15.4129V6.90563C41.3239 6.80405 42.0453 6.64178 42.7468 6.64178C43.6884 6.64178 44.1294 7.02749 44.1294 8.26575V15.4129C44.1294 15.5019 44.2005 15.5738 44.2881 15.5738H47.1765C47.2641 15.5738 47.3354 15.5019 47.3354 15.4129V7.47421C47.3354 5.64707 46.4136 4.75379 44.7103 4.75379Z"
          fill="black"
        />
        <path
          d="M55.9325 13.7671C55.6118 13.889 55.0708 14.2138 54.2493 14.2138C52.7264 14.2138 51.9249 13.1784 51.9249 9.99131C51.9249 6.64162 52.9268 5.56578 54.3694 5.56578C55.111 5.56578 55.5918 5.99206 55.9325 6.41834V13.7671ZM59.1183 2.76439C59.1183 1.71508 59.1536 0.750955 59.1722 0.355259C59.1776 0.239483 59.0806 0.146488 58.9668 0.156786L56.0809 0.488822C55.9968 0.496467 55.9325 0.567774 55.9325 0.653435V3.49524C55.9325 4.22594 55.9725 4.97707 55.9725 4.97707C55.5517 4.85521 54.9907 4.75379 54.4498 4.75379C51.3238 4.75379 48.6387 6.43878 48.6387 10.6613C48.6387 14.3558 50.5823 15.8175 52.5661 15.8175C53.9687 15.8175 55.0908 15.2491 55.9525 14.4981H56.0326L55.9919 15.6186C55.9873 15.738 56.0902 15.8324 56.2067 15.8157L58.9558 15.5047C59.049 15.4916 59.1183 15.4108 59.1183 15.3154V2.76439Z"
          fill="black"
        />
        <path
          d="M67.9592 10.4176C67.9592 6.31691 67.1779 5.54549 65.8152 5.54549C64.5528 5.54549 63.8115 6.47934 63.8115 9.97102C63.8115 14.1327 64.4727 15.0257 65.9353 15.0257C67.1378 15.0257 67.9592 14.3153 67.9592 10.4176ZM60.4453 10.3162C60.4453 6.41833 63.1702 4.75378 65.8754 4.75378C68.7808 4.75378 71.3255 6.51991 71.3255 10.2146C71.3255 14.1123 68.6405 15.8177 65.8754 15.8177C62.97 15.8177 60.4453 14.031 60.4453 10.3162Z"
          fill="black"
        />
        <path
          d="M79.9105 10.4176C79.9105 6.31691 79.1292 5.54549 77.7665 5.54549C76.5041 5.54549 75.7628 6.47934 75.7628 9.97102C75.7628 14.1327 76.4242 15.0257 77.8868 15.0257C79.0892 15.0257 79.9105 14.3153 79.9105 10.4176ZM72.3965 10.3162C72.3965 6.41833 75.1215 4.75378 77.8266 4.75378C80.732 4.75378 83.2767 6.51991 83.2767 10.2146C83.2767 14.1123 80.5918 15.8177 77.8266 15.8177C74.9213 15.8177 72.3965 14.031 72.3965 10.3162Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M90.7241 4.75378C89.5619 4.75378 88.4198 5.64707 87.7988 6.31691H87.7186L87.7575 4.95272C87.7609 4.83664 87.6619 4.74427 87.5479 4.75722L84.7776 5.07225C84.695 5.08161 84.6328 5.15213 84.6328 5.23639V15.3828C84.6328 15.4883 84.7172 15.5738 84.8213 15.5738H87.63C87.7341 15.5738 87.8188 15.4883 87.8188 15.3828V7.14934C88.0993 6.88533 88.5601 6.62133 88.9206 6.62133C89.1011 6.62133 89.2214 6.66205 89.3415 6.88533C89.7222 7.51461 90.0236 7.87832 90.8844 7.87832C91.666 7.87832 92.3648 7.25076 92.3648 6.27634C92.3648 5.28148 91.6859 4.75378 90.7241 4.75378Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_836_12236">
          <rect width="92.4062" height="20.1281" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const OpendoorLogoSVG = () => {
  return (
    <>
      <Box display={['block', null, 'none']} mt="10px">
        <_opendoorLogoSVG height="20" />
      </Box>
      <Box display={['none', null, 'block']} mt="10px">
        <_opendoorLogoSVG height="24" />
      </Box>
    </>
  );
};
