/* storybook-check-ignore */
import { Text, Button, Icon, Link, ListItem } from '@opendoor/bricks/core';

import { MenuItem, NAV_MENU_TEXT_COLOR } from './MenuPanel';

const NAV_MENU_ACTIVE_TEXT_COLOR = '#000000';

interface MenuItemProps extends MenuItem {
  onKeyDown?: (e: any) => void;
  isActive?: boolean;
  setActiveElement?: (activeElement: string) => void;
  hideIcon?: boolean;
}

const MenuListItem = (props: MenuItemProps) => {
  const { href, isActive, displayText, icon = 'chevron-right', hideIcon } = props;
  const itemInner = (
    <>
      <Text
        fontWeight="medium"
        style={{
          color: isActive ? NAV_MENU_ACTIVE_TEXT_COLOR : NAV_MENU_TEXT_COLOR,
          fontSize: '28px',
          lineHeight: '106%',
          letterSpacing: '-0.024em',
        }}
        _hover={{
          backgroundColor: 'transparent',
        }}
      >
        {displayText}
      </Text>
      {!hideIcon && (
        <Icon
          name={icon}
          size={24}
          style={{
            color: NAV_MENU_TEXT_COLOR,
          }}
        />
      )}
    </>
  );

  return (
    <ListItem mb={['30px', null, 8]}>
      {href !== undefined ? (
        // If href prop is available, this item is a link
        <MenuLink href={href as string} {...props}>
          {itemInner}
        </MenuLink>
      ) : (
        <MenuButton {...props}>{itemInner}</MenuButton>
      )}
    </ListItem>
  );
};

const MenuLink: React.FC<Omit<MenuItemProps, 'href'> & { href: string }> = ({
  href,
  displayText,
  elementName,
  onKeyDown,
  children,
}) => (
  <Link
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    width="100%"
    aria-label={`Go to page for ${displayText}`}
    analyticsName={`cosmos-landing-page-menu-link-${elementName}`}
    onKeyDown={onKeyDown}
    href={href}
  >
    {children}
  </Link>
);

const MenuButton: React.FC<MenuItemProps> = ({
  children,
  displayText,
  setActiveElement,
  elementName,
  onKeyDown,
}) => {
  const onClick = () => {
    setActiveElement?.(elementName);
  };
  return (
    <Button
      aria-label={`View subnavigation for ${displayText}`}
      analyticsName="cosmos-landing-page-menu-button"
      alignItems="center"
      width="100%"
      justifyContent="space-between"
      onClick={onClick}
      variant="ghost"
      onKeyDown={onKeyDown}
      _hover={{
        backgroundColor: 'transparent',
      }}
    >
      {children}
    </Button>
  );
};

export default MenuListItem;
