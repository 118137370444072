/*
  Saves us from making a network request to https://imgdrop.imgix.net/2023-06-1687822944103-28395.svg

  storybook-check-ignore
*/
export const GlobalNavSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <rect x="1.75" y="14.4998" width="20.5" height="2.5" rx="1.25" fill="black" />
      <rect x="1.75" y="6.99976" width="20.5" height="2.5" rx="1.25" fill="black" />
    </svg>
  );
};
