/* storybook-check-ignore */
import { Box } from '@opendoor/bricks/core';

interface MenuOverlayProps {
  active: boolean;
  background?: string;
}

const MenuOverlay: React.FC<MenuOverlayProps> = ({
  active,
  background = 'rgba(41, 40, 39, 0.64)',
}) => (
  <Box
    height="100%"
    width="100%"
    opacity={active ? 1 : 0}
    position="fixed"
    top={0}
    left={0}
    zIndex={9}
    transition="all ease-in-out 0.3s"
    display={active ? 'inherit' : 'none'}
    transform={active ? 'translateX(0)' : ''}
    visibility={active ? 'visible' : undefined}
    style={{
      background,
    }}
  />
);

export default MenuOverlay;
