import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { useDevice } from '@opendoor/bricks/hooks/useMedia';
import { colors } from '@opendoor/bricks/theme/eero';
import ImgixImg from 'components/shared/ImgixImg';
import { Link, LinkProps, Box, Text } from '@opendoor/bricks/core';
import RegularContainer from 'components/shared/Footer2022/RegularContainer';
import OpendoorLogoWhite from 'components/shared/icons/OpendoorLogoWhite';
import TwitterLogo from 'components/shared/icons/TwitterLogo';
import FacebookLogo from 'components/shared/icons/FacebookLogo';
import LinkedinLogo from 'components/shared/icons/LinkedinLogo';
import InstagramLogo from 'components/shared/icons/InstagramLogo';
import YoutubeLogo from 'components/shared/icons/YoutubeLogo';
import RvImgixImg from 'components/redventures/shared/RvImgixImg';
import { novo } from '@opendoor/bricks/theme';

const FooterCol = styled(Box)`
  margin-bottom: 94px;

  &:last-child {
    margin-right: 42px;
  }

  @media (max-width: 1199px) {
    margin-bottom: 0;
    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 767px) {
    margin-bottom: 0;
    width: 44%;
  }
`;

const FooterColA: React.FC<{ label: string } & Pick<LinkProps, 'href' | 'style' | 'target'>> = ({
  label,
  href,
  style,
  children,
  target,
}) => {
  const analyticsName = 'cosmos-landing-page-footer-' + label;
  return (
    <Link
      color="neutrals0"
      analyticsName={analyticsName}
      aria-label={label}
      href={href}
      style={style}
      target={target}
    >
      {children}
    </Link>
  );
};

const FooterColUL = styled.ul`
  padding: 0;
  margin: 10px 0 0 0;
  list-style: none;

  @media (max-width: 1200px) {
    margin: 10px 0 30px 0;
  }

  @media (max-width: 767px) {
    margin: 10px 0 44px 0;
  }
`;

const FooterColLI = styled.li`
  font-size: 16px;
  line-height: ${novo.lineHeights[112]};
  padding: 0;
  margin: 10px 0;

  @media (max-width: 1200px) {
    font-size: 14px;
    margin: 10px 0;
  }

  @media (max-width: 767px) {
    font-size: 16px;
    margin: 13px 0;
  }
`;

const hiddenXSDown = css`
  @media (max-width: 767px) {
    display: none !important;
  }
`;

const hiddenMDUp = css`
  @media (min-width: 768px) {
    display: none !important;
  }
`;

const hiddenLGUp = css`
  @media (min-width: 1200px) {
    display: none !important;
  }
`;

const leftSpaceXs = css`
  @media (max-width: 767px) {
    margin-left: 25px;
  }
`;

const H2 = styled(Text)`
  color: ${colors.neutrals0};
  margin-bottom: 85px;

  @media (max-width: 1200px) {
    margin-bottom: 50px;
  }

  @media (max-width: 767px) {
    margin-bottom: 42px;
  }
`;

const H5 = styled.h5`
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
  color: ${colors.neutrals0};
`;

const H6 = styled.h6`
  margin: 0;
  font-size: 16px;
  color: ${colors.neutrals0};
  font-weight: ${novo.fontWeights.medium};

  @media (max-width: 1200px) {
    font-size: 14px;
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

const SocialIcons = styled.div`
  min-width: initial;
  margin-bottom: 6px;

  a {
    display: inline-block;
    margin: 0 15px;

    :last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 1200px) {
    min-width: 231px;
    position: initial;
  }

  @media (max-width: 767px) {
    display: flex;

    a:first-child {
      margin-left: 0;
    }
  }
`;

const FooterSection = styled.footer`
  background: #222;
  padding: 45px 38px 60px;
  position: relative;
  transform: translate3d(0, 0, 0);
  margin-top: -10px;

  @media (max-width: 1200px) {
    padding: 45px 31px 60px;
  }

  @media (max-width: 767px) {
    padding: 26px 24px 25px;
  }
`;

const FairHousing = styled.div`
  img {
    height: 40px;
    width: 41px;
    margin-right: 30px;
  }
`;

const LegalP = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: ${colors.neutrals0};

  a {
    text-decoration: underline;
    color: ${colors.neutrals0};
  }
`;

const termsAndConditions = css`
  align-items: flex-start;
  display: flex;
  margin-top: 0;
  justify-content: space-between;

  h6 {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 5px;
    text-transform: none;

    @media (max-width: 1200px) {
      font-size: 14px;
      line-height: 18px;
    }

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;

    @media (max-width: 1200px) {
      max-width: 270px;
      margin-top: 0;
    }

    @media (max-width: 767px) {
      max-wdith: 100%;
    }
  }

  li {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0 !important;

    @media (max-width: 1200px) {
      font-size: 14px;
      line-height: 28px;
    }

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 28px;
    }
  }

  @media (max-width: 1200px) {
    flex-wrap: wrap;
    margin: 0;
    justify-content: space-between;
  }

  @media (max-width: 767px) {
    margin: 20px 0 30px 0;
  }
`;

const odLogoGray = css`
  height: 28px;
  margin-bottom: 7px;
  width: 130px;
`;

const copyRow = css`
  display: flex;
  flex-direction: row;
  color: ${colors.neutrals0};
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  justify-content: flex-start;
  margin-top: 63px;

  @media (max-width: 1200px) {
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 33px;
  }

  @media (max-width: 767px) {
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    margin-top: 42px;

    span {
      margin-top: 7px;
    }
  }
`;

interface IProps {
  hideLegalese?: boolean;
  addLegalese?: React.ReactNode;
}

/* storybook-check-ignore */
const Footer: React.FC<IProps> = (props: IProps) => {
  const { isMobile, isDesktop } = useDevice();

  return (
    <FooterSection>
      <RegularContainer>
        <RvImgixImg
          responsiveSrc={'76959525cf044b4aabd5bcdc75a2eec9.png'}
          alt="Opendoor"
          css={odLogoGray}
          height={12}
        />
        <H2 as="h2" size="170" fontWeight="medium" mt={4}>
          The better way to
          <br /> buy and sell
        </H2>
      </RegularContainer>
      <RegularContainer>
        <Box
          display={['flex']}
          flexDirection={['column', null, 'row']}
          mb={[10, null, null, 11]}
          gap={10}
        >
          <Box mr={['81px', null, null, '63px']}>
            <H5>Call Us</H5>
            <FooterColA
              label="contact-opendoor-phone"
              aria-label="Contact Opendoor by phone"
              href="tel:888-352-7075"
            >
              <Text
                textDecoration={`${isMobile ? 'underline' : 'none'}`}
                fontSize="24px"
                color="neutrals0"
                fontWeight="medium"
              >
                888-352-7075
              </Text>
            </FooterColA>
          </Box>
          <Box>
            <H5>Email Us</H5>
            <FooterColA
              label="contact-opendoor-email"
              aria-label="Contact Opendoor by email"
              href="mailto:support@opendoor.com"
            >
              <Text
                textDecoration={`${isMobile ? 'underline' : 'none'}`}
                fontSize="24px"
                color="neutrals0"
                fontWeight="medium"
              >
                support@opendoor.com
              </Text>
            </FooterColA>
          </Box>
        </Box>
      </RegularContainer>
      <RegularContainer
        css={hiddenXSDown}
        style={{
          borderBottom: '1px solid #e9e9e9',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <FooterCol>
          <H6>Sell your home</H6>
          <FooterColUL>
            <FooterColLI>
              <FooterColA label="sell-your-home-request-offer" href="https://www.opendoor.com/">
                Request an offer
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="sell-your-home-pricing" href="https://www.opendoor.com/w/pricing">
                Pricing
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="sell-your-home-reviews" href="https://www.opendoor.com/reviews">
                Reviews
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="sell-your-home-stories" href="https://www.opendoor.com/w/stories">
                Customer Stories
              </FooterColA>
            </FooterColLI>
          </FooterColUL>
        </FooterCol>
        <FooterCol>
          <H6>Buy a home</H6>
          <FooterColUL>
            <FooterColLI>
              <FooterColA label="buy-a-home-buy" href="https://www.opendoor.com/w/homes">
                Buy
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="exclusive" href="https://opendoor.com/exclusive">
                Exclusives
              </FooterColA>
            </FooterColLI>
          </FooterColUL>
        </FooterCol>
        <FooterCol>
          <H6>Buy &amp; Sell</H6>
          <FooterColUL>
            <FooterColLI>
              <FooterColA
                label="buy-and-sell-with-opendoor"
                href="https://www.opendoor.com/buy-and-sell"
              >
                Opendoor Complete
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="trade-in-builder" href="https://www.opendoor.com/w/builder">
                Builder trade-up
              </FooterColA>
            </FooterColLI>
          </FooterColUL>
        </FooterCol>
        <FooterCol css={leftSpaceXs}>
          <H6>About</H6>
          <FooterColUL>
            <FooterColLI>
              <FooterColA label="about-company" href="https://www.opendoor.com/w/about">
                Company
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="about-career" href="https://www.opendoor.com/jobs">
                Careers
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="about-design" href="https://design.opendoor.com/">
                Design
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="about-press" href="https://www.opendoor.com/press">
                Press
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="about-contact" href="https://www.opendoor.com/w/contact-us">
                Contact
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="about-investors" href="https://investor.opendoor.com/">
                Investors
              </FooterColA>
            </FooterColLI>
          </FooterColUL>
        </FooterCol>
        <FooterCol css={hiddenXSDown}>
          <H6>Resources</H6>
          <FooterColUL>
            <FooterColLI>
              <FooterColA label="resources-blog" href="https://www.opendoor.com/w/blog">
                Blog
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="resources-guides" href="https://www.opendoor.com/w/resources">
                Guides
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="resources-faq" href="https://www.opendoor.com/faq">
                FAQ
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="resources-help-center" href="https://help.opendoor.com">
                Help Center
              </FooterColA>
            </FooterColLI>
          </FooterColUL>
        </FooterCol>
        <FooterCol css={hiddenXSDown}>
          <H6>Agents</H6>
          <FooterColUL>
            <FooterColLI>
              <FooterColA
                label="agents-make-offer"
                href="https://www.opendoor.com/make-offer/start"
              >
                Make an offer
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="agents-request-offer" href="https://www.opendoor.com/w/agents">
                Request an offer
              </FooterColA>
            </FooterColLI>
          </FooterColUL>
        </FooterCol>
      </RegularContainer>

      <RegularContainer
        css={hiddenMDUp}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          borderBottom: '1px solid #e9e9e9',
        }}
      >
        <FooterCol>
          <H6>Service</H6>
          <FooterColUL>
            <FooterColLI>
              <FooterColA label="service-request-offer" href="https://www.opendoor.com/">
                Sell your home
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="service-buy" href="https://www.opendoor.com/w/homes">
                Buy a home
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA
                label="service-trade-in-builder"
                href="https://www.opendoor.com/w/builder"
              >
                Trade-in
              </FooterColA>
            </FooterColLI>
          </FooterColUL>
        </FooterCol>
        <FooterCol>
          <H6>Customers</H6>
          <FooterColUL>
            <FooterColLI>
              <FooterColA label="customers-home-seller" href="https://www.opendoor.com">
                Home seller
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="customers-home-buyer" href="https://www.opendoor.com/w/homes">
                Home buyer
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA
                label="customers-real-estate-agents"
                href="https://www.opendoor.com/w/agents"
              >
                Real estate agents
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA
                label="customers-home-builders"
                href="https://www.opendoor.com/w/partner/builder"
              >
                Home builders
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA
                label="customers-vendors"
                href="https://www.opendoor.com/w/vendor-partnerships"
              >
                Vendors
              </FooterColA>
            </FooterColLI>
          </FooterColUL>
        </FooterCol>
        <FooterCol>
          <H6>Resources</H6>
          <FooterColUL>
            <FooterColLI>
              <FooterColA label="resources-guides" href="https://www.opendoor.com/w/resources">
                Guides
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="resources-blog" href="https://www.opendoor.com/w/blog">
                Blog
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="resources-faq" href="https://www.opendoor.com/faq">
                FAQ
              </FooterColA>
            </FooterColLI>
          </FooterColUL>
        </FooterCol>
        <FooterCol>
          <H6>About us</H6>
          <FooterColUL>
            <FooterColLI>
              <FooterColA label="about-company" href="https://www.opendoor.com/w/about">
                Who we are
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="about-career" href="https://www.opendoor.com/w/contact-us">
                Contact us
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="about-press" href="https://www.opendoor.com/jobs">
                Jobs
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="about-press" href="https://www.opendoor.com/w/resources">
                How it works
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="about-contact" href="https://www.opendoor.com/press">
                Press
              </FooterColA>
            </FooterColLI>
            <FooterColLI>
              <FooterColA label="about-investors" href="https://www.opendoor.com/w/pricing">
                Pricing
              </FooterColA>
            </FooterColLI>
          </FooterColUL>
        </FooterCol>
      </RegularContainer>

      <RegularContainer
        style={{ marginTop: `${isMobile ? '50px' : '44px'}`, alignItems: 'flex-start' }}
      >
        <Box css={termsAndConditions}>
          <H6>Terms and Privacy</H6>
          <FooterColUL>
            <FooterColLI style={{ margin: `${isDesktop ? '0 24px 0 0' : '0 24px 12px 0'}` }}>
              <FooterColA label="terms-trust-safety" href="https://www.opendoor.com/w/safety">
                Trust and Safety
              </FooterColA>
            </FooterColLI>
            <FooterColLI style={{ margin: `${isDesktop ? '0 24px 0 0' : '0 24px 12px 0'}` }}>
              <FooterColA label="terms-tos" href="https://www.opendoor.com/w/terms">
                Terms of Service
              </FooterColA>
            </FooterColLI>
            <FooterColLI style={{ margin: `${isDesktop ? '0 24px 0 0' : '0 24px 12px 0'}` }}>
              <FooterColA label="terms-privacy" href="https://www.opendoor.com/w/privacy">
                Privacy Policy
              </FooterColA>
            </FooterColLI>
            <FooterColLI style={{ margin: `${isDesktop ? '0 24px 0 0' : '0 24px 12px 0'}` }}>
              <FooterColA label="terms-privacy" href="https://www.opendoor.com/w/privacy#ccpa">
                Notice at Collection
              </FooterColA>
            </FooterColLI>
            <FooterColLI style={{ margin: `${isDesktop ? '0 24px 0 0' : '0 24px 12px 0'}` }}>
              <FooterColA label="terms-do-not-sell" href="https://www.opendoor.com/privacyrequest">
                Do not sell my info
              </FooterColA>
            </FooterColLI>
            <FooterColLI style={{ margin: `${isDesktop ? '0 24px 0 0' : '0 24px 12px 0'}` }}>
              <FooterColA
                label="terms-brokerage-representation"
                href="https://www.opendoor.com/w/terms#brokerages"
              >
                Opendoor Brokerages
              </FooterColA>
            </FooterColLI>
          </FooterColUL>
          <Box display={['block', null, null, null, 'none']}>
            <SocialIcons>
              <FooterColA
                label="facebook-social"
                href="https://www.facebook.com/OpendoorHQ"
                target="_blank"
              >
                <FacebookLogo />
              </FooterColA>
              <FooterColA
                label="twitter-social"
                href="https://twitter.com/Opendoor"
                target="_blank"
              >
                <TwitterLogo />
              </FooterColA>
              <FooterColA
                label="linkedin-social"
                href="https://www.linkedin.com/company/opendoor-com/"
                target="_blank"
              >
                <LinkedinLogo />
              </FooterColA>
              <FooterColA
                label="instagram-social"
                href="https://www.instagram.com/opendoor/?hl=en"
                style={{ position: 'relative', bottom: `${isMobile ? '2px' : '-3px'}` }}
                target="_blank"
              >
                <InstagramLogo />
              </FooterColA>
              <FooterColA
                label="youtube-social"
                href="https://www.youtube.com/c/Opendoor"
                style={{ position: 'relative', bottom: `${isMobile ? '2px' : '-3px'}` }}
                target="_blank"
              >
                <YoutubeLogo />
              </FooterColA>
            </SocialIcons>
          </Box>
        </Box>
      </RegularContainer>

      <RegularContainer css={hiddenLGUp} style={{ marginTop: `${isMobile ? '32px' : '0'}` }}>
        <H6 style={{ marginBottom: '15px', fontSize: '14px' }}>Get the app</H6>
        <FooterCol style={{ display: 'flex' }} flexDir={['column', 'row']} gap={[4, 0]}>
          <FooterColA
            label="app-download-ios"
            href="https://itunes.apple.com/us/app/opendoor-homes-all-day-open/id1143708068?mt=8"
            style={{ display: 'block', marginRight: isMobile ? '0px' : '16px' }}
          >
            <ImgixImg
              src="117a9e6145474ceb9e897198206fb73a.png"
              alt="Get the Opendoor app on your iPhone from the App Store."
              width={142}
              height={42}
            />
          </FooterColA>
          <FooterColA
            label="app-download-android"
            href="https://play.google.com/store/apps/details?id=com.opendoor.buyerapp&hl=en"
            style={{ display: 'block' }}
          >
            <ImgixImg
              src="ae16807cb9e242f5801f6036a207a471.png"
              alt="Get the Opendoor app on your Android phone from the Google Play Store."
              width={142}
              height={42}
            />
          </FooterColA>
        </FooterCol>
      </RegularContainer>

      <RegularContainer
        style={{
          marginTop: `${isMobile ? '41px' : '38px'}`,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box maxWidth={['100%', null, null, '927px']}>
          {!props.hideLegalese && (
            <>
              <LegalP>Offer eligibility and purchase price vary.</LegalP>
              <br />
            </>
          )}
          <LegalP>
            Affiliation Disclosure: Please be advised that Opendoor Brokerage LLC, Opendoor
            Brokerage Inc., and Opendoor Home Loans LLC are wholly-owned subsidiaries of Opendoor.
            Because of these relationships, transactions with these entities may provide Opendoor a
            financial or other benefit. You are NOT required to transact with any of these entities
            as a condition of working with Opendoor. THERE ARE FREQUENTLY OTHER PROVIDERS AVAILABLE
            WITH SIMILAR SERVICES. YOU ARE FREE TO SHOP AROUND TO DETERMINE THAT YOU ARE RECEIVING
            THE BEST SERVICES AND THE BEST RATES FOR THESE SERVICES.
          </LegalP>
          <br />
          <LegalP>
            <a href="https://www.opendoor.com/w/terms#brokerages">
              Learn more about Opendoor Brokerages
            </a>
          </LegalP>
          <LegalP>
            Opendoor Brokerage LLC and Opendoor Brokerage Inc. holds real estate brokerage{' '}
            <a href="https://www.opendoor.com/articles/brokerage">licenses</a> in multiple states.
          </LegalP>
          <br />
          <LegalP>Opendoor Brokerage Inc. California DRE# 02061130</LegalP>
          <br />
          <LegalP>
            <a href="https://s3.amazonaws.com/odfil.es/static/documents/SOP-NYS-OD.pdf">
              § 442-H New York Standard Operating Procedures
            </a>
            <br />
            <a href="https://s3.amazonaws.com/odfil.es/static/documents/fair-housing-notice.pdf">
              § New York Fair Housing Notice
            </a>
            <br />
            TREC:{' '}
            <a href="https://www.opendoor.com/w/wp-content/uploads/2020/12/opendoor-brokerage-TREC-IABS-1-0.pdf">
              Information About Brokerage Services
            </a>{' '}
            |{' '}
            <a href="https://www.trec.texas.gov/forms/consumer-protection-notice">
              Consumer Protection Notice
            </a>
          </LegalP>
          <br />
          <LegalP>
            <a href="https://www.opendoor.com/w/contact-us">
              Contact Opendoor Brokerage LLC and Opendoor Brokerage Inc
            </a>
          </LegalP>
          {props.addLegalese && (
            <>
              <LegalP>{props.addLegalese}</LegalP>
              <br />
            </>
          )}
          <>
            <br />
            <LegalP>
              Opendoor Labs Inc. is working with{' '}
              <a href="https://accessible360.com/" rel="noopener noreferrer">
                Accessible360
              </a>{' '}
              to enhance the accessibility of our websites and mobile applications. We view
              accessibility as a continually ongoing effort. Please contact us at
              support@opendoor.com with any feedback or suggestions.
            </LegalP>
          </>
        </Box>
        <Box display={['none', null, null, null, 'block']}>
          <SocialIcons>
            <FooterColA
              label="facebook-social"
              href="https://www.facebook.com/OpendoorHQ"
              target="_blank"
            >
              <FacebookLogo />
            </FooterColA>
            <FooterColA label="twitter-social" href="https://twitter.com/Opendoor" target="_blank">
              <TwitterLogo />
            </FooterColA>
            <FooterColA
              label="linkedin-social"
              href="https://www.linkedin.com/company/opendoor-com/"
              target="_blank"
            >
              <LinkedinLogo />
            </FooterColA>
            <FooterColA
              label="instagram-social"
              href="https://www.instagram.com/opendoor/?hl=en"
              style={{ position: 'relative', bottom: `${isMobile ? '2px' : '-3px'}` }}
              target="_blank"
            >
              <InstagramLogo />
            </FooterColA>
            <FooterColA
              label="youtube-social"
              href="https://www.youtube.com/c/Opendoor"
              style={{ position: 'relative', bottom: `${isMobile ? '2px' : '-3px'}` }}
              target="_blank"
            >
              <YoutubeLogo />
            </FooterColA>
          </SocialIcons>
          <H6 style={{ marginBottom: '15px', fontSize: '14px', position: 'relative', top: '3px' }}>
            Get the app
          </H6>
          <FooterCol style={{ display: 'flex', marginRight: 0 }}>
            <FooterColA
              label="app-download-ios"
              href="https://itunes.apple.com/us/app/opendoor-homes-all-day-open/id1143708068?mt=8"
              style={{ display: 'block', marginRight: '16px' }}
            >
              <ImgixImg
                src="117a9e6145474ceb9e897198206fb73a.png"
                alt="Get the Opendoor app on your iPhone from the App Store."
                width={142}
                height={42}
              />
            </FooterColA>
            <FooterColA
              label="app-download-android"
              href="https://play.google.com/store/apps/details?id=com.opendoor.buyerapp&hl=en"
              style={{ display: 'block' }}
            >
              <ImgixImg
                src="ae16807cb9e242f5801f6036a207a471.png"
                alt="Get the Opendoor app on your Android phone from the Google Play Store."
                width={142}
                height={42}
              />
            </FooterColA>
          </FooterCol>
        </Box>
      </RegularContainer>
      <RegularContainer css={copyRow}>
        <Box
          display="flex"
          order={[2, null, 1]}
          flexDirection="column"
          analyticsName="cosmos-footer-section"
        >
          <OpendoorLogoWhite />
          <span>&copy; {new Date().getFullYear()} Opendoor</span>
        </Box>
        <Box
          alignItems="center"
          display={['flex', null, 'flex']}
          mb={['39px', null, 0]}
          order={[1, null, 2]}
          ml={[0, null, '40px']}
        >
          <FairHousing>
            <RvImgixImg
              alt="Opendoor adheres to fair housing laws as part of Equal Housing Opportunity."
              responsiveSrc={'f2398a1e83a24962a9297d13f2ac6ea5.png'}
              width="100%"
            />
          </FairHousing>
          <a
            href="https://www.bbb.org/us/az/tempe/profile/real-estate-services/opendoor-1126-1000060421/#sealclick"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <RvImgixImg
              responsiveSrc={'1cd65eee0b8345689ceff0292986f125.png'}
              alt="Better Business Bureau accredited since June 8th 2021"
              style={{ display: 'block', margin: 'auto' }}
              height="50px"
              width="135px"
            />
          </a>
        </Box>
      </RegularContainer>
    </FooterSection>
  );
};

export default Footer;
